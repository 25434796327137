import React, { useMemo } from "react";
import { NameContent, NameWrapper, SearchItem, SectionTitle } from "./styles";
import { StyledParagraph, StyledText, StyledTitle } from "../CustomAntdComponents/Typography";
import { useStore } from "../../store/global/appStore";
import { FixedSizeList as List } from "react-window";
import { SuggestionType } from "./index";
import styled from "styled-components";

const suggestedSearches: RoadRoute[] = [
  {
    "TerminalId": 921,
    "TerminalName": "Abia (Aba)",
    "IsDeparture": true,
    "IsArrival": true,
  },

  {
    "TerminalId": 924,
    "TerminalName": "Anambra (Awka)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 926,
    "TerminalName": "Delta (Asaba-Onitsha)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 931,
    "TerminalName": "Enugu (Enugu)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 941,
    "TerminalName": "Lagos (Ejigbo)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 937,
    "TerminalName": "Lagos (Okota)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 945,
    "TerminalName": "Lagos (Ajah)",
    "IsDeparture": true,
    "IsArrival": true,
  },

  //   {
  //     "TerminalId": 946,
  //     "TerminalName": "Lagos (Jibowu)",
  //     "IsDeparture": true,
  //     "IsArrival": true,
  //   },
  {
    "TerminalId": 920,
    "TerminalName": "Abia (Umuahia)",
    "IsDeparture": true,
    "IsArrival": true,
  },

  {
    "TerminalId": 934,
    "TerminalName": "Imo (Orlu)",
    "IsDeparture": true,
    "IsArrival": true,
  },
  {
    "TerminalId": 932,
    "TerminalName": "Imo (Owerri)",
    "IsDeparture": true,
    "IsArrival": true,
  },
];

const SelectRoadSearches = ({
  onSelect,
  searchText,
  height = 500,
  limitSuggestionHeight,
}: {
  limitSuggestionHeight?: boolean;
  height?: number;
  onSelect: (airport: string) => void;
  searchText: string;
}) => {
  const { roadRoutes } = useStore();

  const filteredOptions = useMemo(() => {
    const textLower = searchText.toLowerCase();
    return roadRoutes.filter((option) => {
      return (option?.TerminalName).toLowerCase().includes(textLower);
    });
  }, [roadRoutes, searchText]);

  const filteredLength = filteredOptions.length;
  const suggestionHeight = limitSuggestionHeight ? `${height}px` : "100%";

  return (
    <div>
      {searchText ? (
        <>
          {filteredLength === 0 && (
            <Content height={suggestionHeight}>
              <SectionTitle>
                <StyledParagraph align="center" size="normal">
                  No results found.
                </StyledParagraph>
              </SectionTitle>
            </Content>
          )}
          <List height={height} itemCount={filteredLength} itemSize={50} width={"100%"}>
            {({ index, style }) => {
              const option = filteredOptions[index];
              return (
                <div style={{ ...style, paddingLeft: "16px", paddingRight: "16px" }}>
                  <SearchItem key={index} onClick={() => onSelect(option.TerminalId.toString())}>
                    <NameWrapper>
                      <NameContent>
                        <StyledTitle level={5}>{option.TerminalName}</StyledTitle>
                      </NameContent>
                    </NameWrapper>
                  </SearchItem>
                </div>
              );
            }}
          </List>
        </>
      ) : (
        null
        // <Content height={suggestionHeight}>
        //   <SectionTitle>
        //     <StyledText>Suggested Searches</StyledText>
        //   </SectionTitle>
        //   {suggestedSearches.map((search, index) => (
        //     <SearchItem key={index} onClick={() => onSelect(search.TerminalId.toString())}>
        //       <NameWrapper>
        //         <NameContent>
        //           <StyledTitle level={5}>{search.TerminalName}</StyledTitle>
        //         </NameContent>
        //       </NameWrapper>
        //     </SearchItem>
        //   ))}
        // </Content>
      )
      }
    </div>
  );
};

const Content = styled.div<{ height: string }>`
  padding: 0 16px;
  max-height: ${({ height }) => `${height}`};
  overflow-y: auto;
`;

export default SelectRoadSearches;
