import styled, { css } from "styled-components";
import { Typography } from "antd";
import { GREY, PRIMARY, FONT_SIZE } from "../../utils/colors";
import device from "../../utils/mediaQuery";

const { Paragraph, Title, Text } = Typography;

type StyledParagraphProp = {
  align?: string;
  size?: string;
  color?: string;
  padded?: string;
};

const getSizePixel = (size?: string) => {
  let result = "";
  switch (size) {
    case "small":
      result = "10px";
      break;
    case "normal":
      result = "14px";
      break;
    case "medium":
      result = "16px";
      break;
    case "extra":
      result = "20px";
      break;
    default:
      result = "14px";
  }

  return result;
};

const getLevelFontSize = (level) => {
  switch (level) {
    case 1:
      return "32px"; // Example size, can be adjusted based on your theme
    case 2:
      return "28px";
    case 3:
      return "24px";
    case 4:
      return "18px";
    case 5:
      return "14px";
    default:
      return "16px"; // Default size if the level is not provided
  }
};

export const StyledParagraph = styled(Paragraph)<StyledParagraphProp>`
  margin: 0 !important;
  text-align: ${({ align }) => align || "left"};

  ${({ size }) =>
    size &&
    css`
      font-size: calc(${getSizePixel(size)} * ${FONT_SIZE}) !important;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color === "primary" ? PRIMARY : GREY};
    `}
  
  ${({ padded }) =>
    padded &&
    css`
      margin: 10px 0 !important;
    `}
`;

type StyledTitleProp = {
  align?: string;
  color?: string;
  size?: string;
};

export const StyledTitle = styled(Title)<StyledTitleProp>`
  text-align: ${({ align }) => align || "left"};
  margin: 0 !important;

  ${({ size }) =>
    size &&
    css`
      font-size: calc(${getSizePixel(size)} * ${FONT_SIZE}) !important;
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color === "primary" ? PRIMARY : GREY}!important;
    `}

    @media (max-width: 1200px) and (min-width: 992px) {
    ${({ level }) =>
      level &&
      css`
        font-size: calc(${getLevelFontSize(level)} * 0.8) !important;
      `}
  }
`;

type StyledTextProp = {
  size?: string;
  color?: string;
};
export const StyledText = styled(Text)<StyledTextProp>`
  margin: 0 !important;

  ${({ size }) =>
    size &&
    css`
      font-size: calc(${getSizePixel(size)} * ${FONT_SIZE});
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color === "primary" ? PRIMARY : GREY}!important;
    `}
`;
