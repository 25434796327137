/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Col, Row, Drawer, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { DesktopContentWrapper, ListingHeaderContentWrapper, MobileContentWrapper, Wrapper } from "./styles";
import { useFlightSearchStore } from "../../store/flightSearch/flightSearchStore";
import { SEARCH_DOMESTIC_FLIGHTS_URL, SEARCH_INTERNATIONAL_FLIGHTS_URL } from "../../utils/constants";
import { postAPI } from "../../utils/api";
import { buildFlightSearchPayload, buildInternationalTripResults, getDurationInMinutes } from "./helpers";
import { isMobileScreen } from "../../utils/helpers";
import FlightRequestLoading from "../../components/RequestLoading";
import FlightHeaderNavigation from "../../components/HeaderNavigation/Flight";
import FlightSelectInfo from "../../components/AvailableFlights/FlightSelectInfo";
import ListingFilterMobile from "../../components/ListingFilter/ListingFilterMobile";
import AvailableFlights from "../../components/AvailableFlights";
import ListingFilterDesktop from "../../components/ListingFilter/ListingFilterDesktop";
import Sort from "../../components/ListingFilter/FlightFilters/Sort";
import FlightItinerary from "../../components/FlightItinerary";
import FlightPriceBreakdown from "../../components/FlightItinerary/FlightPriceBreakdown";
import moment, { Moment } from "moment";
import EmptyFlightResult from "../../components/EmptyResult";
import FlightDateFilter from "../../components/AvailableFlights/FlightDateFilter";
import { useStore } from "../../store/global/appStore";

function SearchListings() {
  const navigate = useNavigate();
  const drawerContainerRef = useRef(null);
  const [filteredAvailableFlights, setFilteredAvailableFlights] = useState<FlightAggregateResultItem>();
  const [availableFlights, setAvailableFlights] = useState<FlightAggregateResultItem>();
  const [error, setError] = useState<string | null>();
  const [activeAirlineHit, setActiveAirlineHit] = useState<FlightResultItem | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openModify, setOpenModify] = useState<boolean>(false);
  const [selectedAirlines, setSelectedAirlines] = useState<string[]>([]);
  const [selectedStopOver, setSelectedStopOver] = useState<number | null>(null);
  const [selectedFlights, setSelectedFlights] = useState<AirlineResultItem[]>([]);
  const [sortBy, setSortBy] = useState("cheapest");
  const [airlines, setAirlines] = useState<AirlineOption[]>([]);

  const { formData, updateFormData, updateStoreData } = useFlightSearchStore();

  const isDomestic = formData.isDomestic;
  const isMobile = isMobileScreen();
  const isOneWay = formData.tripType === "one_way";
  const isReturning = selectedFlights.length > 0;
  const { airports } = useStore();

  const allAirports = [...airports];

  const searchFlights = useCallback(
    async (payload = formData) => {
      setFilteredAvailableFlights(null);
      setAvailableFlights(null);
      setLoadingData(true);
      setError(null);
      setSelectedFlights([]);
      const isDomestic = payload.isDomestic;
      const url = isDomestic ? SEARCH_DOMESTIC_FLIGHTS_URL : SEARCH_INTERNATIONAL_FLIGHTS_URL;

      const reformedPayload = buildFlightSearchPayload(payload);
      const searchResults = await postAPI(url, reformedPayload);

      if (searchResults.status === 200) {
        if (isDomestic) {
          if (searchResults.data && searchResults.data?.ResultList && searchResults?.data?.ResultList?.length > 0) {
            setAvailableFlights(searchResults.data);
            setFilteredAvailableFlights(searchResults.data);
          }else{
            setAvailableFlights(null);
            setFilteredAvailableFlights(null);
            setError("Preferred itinerary has no inventory available");
          }
        } else {
          if(searchResults.data && searchResults.data.ResultList && searchResults.data.ResultList.length > 0){
            const reformedData = buildInternationalTripResults(searchResults.data);
            setAvailableFlights(reformedData);
            setFilteredAvailableFlights(reformedData);
            
          }else{
            setAvailableFlights(null);
            setFilteredAvailableFlights(null);
            setError("Preferred itinerary has no inventory available");
          }
        }
        setLoadingData(false);
      } else {
        setError(searchResults.data);
        setLoadingData(false);
      }
    },
    [formData, isDomestic]
  );

  const cheapestFares = useMemo(() => {
    if (!availableFlights || availableFlights.TotalCount === 0) {
      return null;
    }

    const cheapestDeparture = availableFlights.ResultList[0].ResultList[0].TotalFare;
    const cheapestReturn = availableFlights.ResultList[1]
      ? availableFlights.ResultList[1].ResultList[0].TotalFare
      : null;

    return {
      cheapestDeparture,
      cheapestReturn,
    };
  }, [availableFlights]);

  const farePriceRange = useMemo(() => {
    if (!availableFlights || availableFlights.TotalCount === 0) {
      return { min: 0, max: 0 };
    }

    if (selectedFlights.length === 0) {
      const departureFares = availableFlights.ResultList[0].ResultList.map((f) => f.TotalFare);
      return {
        min: Math.min(...departureFares),
        max: Math.max(...departureFares),
      };
    } else if (selectedFlights.length === 1) {
      const returnFares = availableFlights.ResultList[1]
        ? availableFlights.ResultList[1].ResultList.map((f) => f.TotalFare)
        : null;
      return returnFares ? { min: Math.min(...returnFares), max: Math.max(...returnFares) } : { min: 0, max: 0 };
    }
  }, [availableFlights]);

  const handleResetFilters = () => {
    setSelectedAirlines(airlines.map((a) => a.code));
    setSelectedStopOver(null);
    setSortBy("cheapest");
    setFilteredAvailableFlights(availableFlights);
  };

  const renderAvailableFlights = () => (
    <AvailableFlights
      results={filteredAvailableFlights}
      loading={loadingData}
      onBookingRedirect={handleBookingRedirect}
      selectedFlights={selectedFlights}
      onFlightSelect={(ar) => {
        handleResetFilters();
        setSelectedFlights(ar);
      }}
      cheapestFares={cheapestFares}
    />
  );

  const handleNavigation = () => {
    if (activeAirlineHit) {
      setActiveAirlineHit(null);
    } else {
      navigate(-1);
    }
  };

  const handleBookingRedirect = (selectedFlight: AirlineResultItem[]) => {
    updateStoreData({ selectedFlight });
    if (isMobile) {
      navigate("/itinerary/flight");
    } else {
      setOpenDrawer(true);
    }
  };

  const handleAirlineFiltering = (selectedAirlines: string[]) => {
    if (selectedAirlines.length === 0 || selectedAirlines.length === airlines.length) {
      setFilteredAvailableFlights(availableFlights);
      handleSort(sortBy, availableFlights);
      setSelectedAirlines(selectedAirlines);
    } else {
      const isReturning = selectedFlights.length > 0 && isDomestic;
      const resultIndex = isReturning ? 1 : 0;
      const unFilteredResultIndex = isReturning ? 0 : 1;
      const resultItems = availableFlights.ResultList[resultIndex];

      const filtered = resultItems.ResultList.filter((resultItem) =>
        selectedAirlines.some((airline) =>
          resultItem.FlightDetails.map((fd) => fd.OperatingAirlineCode).includes(airline)
        )
      );

      const totalFiltered = filtered.length;

      let filteredResultList = [];

      if (availableFlights.ResultList[unFilteredResultIndex]) {
        filteredResultList[unFilteredResultIndex] = {
          TotalCount: availableFlights.ResultList[unFilteredResultIndex].TotalCount,
          ResultList: availableFlights.ResultList[unFilteredResultIndex].ResultList,
        };
      }

      filteredResultList[resultIndex] = {
        TotalCount: totalFiltered,
        ResultList: filtered,
      };
      const newFiltered = {
        TotalCount: availableFlights.TotalCount,
        ResultList: filteredResultList,
      };

      setFilteredAvailableFlights(newFiltered);
      handleSort(sortBy, newFiltered);
      setSelectedAirlines(selectedAirlines);
    }
  };

  const handleStopoverFiltering = (stopOver: number | null) => {
    if (stopOver === -1 || stopOver === null) {
      setFilteredAvailableFlights(availableFlights);
      handleSort(sortBy, availableFlights);
      setSelectedStopOver(stopOver);
    } else {
      // Stopover filtering is only applicable for international flights
      const resultItems = availableFlights.ResultList[0];
      const filteredFlightResultList = [];
      resultItems.ResultList.forEach((f) => {
        const findStopOver = f.FlightDetails.find((fd) => fd.StopOvers === stopOver);
        if (findStopOver) {
          filteredFlightResultList.push(f);
        }
      });

      const totalFiltered = filteredFlightResultList.length;

      const filteredResults = {
        TotalCount: availableFlights.TotalCount,
        ResultList: [
          {
            TotalCount: totalFiltered,
            ResultList: filteredFlightResultList,
          },
        ],
      };

      setFilteredAvailableFlights(filteredResults);
      handleSort(sortBy, filteredResults);
      setSelectedStopOver(stopOver);
    }
  };

  const handleSort = (value: string, data = filteredAvailableFlights) => {
    setSortBy(value);
    switch (value) {
      case "cheapest":
        handleSortByPrice("asc", data);
        return;
      case "fastest":
        handleSortByDuration("asc", data);
        return;
      case "earliest":
        handleSortByDate("desc", data);
        return;
    }
  };

  const handleSortByPrice = (order: string | null, data = filteredAvailableFlights) => {
    const isReturning = selectedFlights.length > 0;
    const resultIndex = isReturning ? 1 : 0;
    const filteredResults = data || filteredAvailableFlights;
    if (!filteredResults) {
      return;
    }

    const sortedRecords =
      order === "asc"
        ? filteredResults.ResultList[resultIndex].ResultList.sort(
            (a: AirlineResultItem, b: AirlineResultItem) => a.TotalFare - b.TotalFare
          )
        : filteredResults.ResultList[resultIndex].ResultList.sort(
            (a: AirlineResultItem, b: AirlineResultItem) => b.TotalFare - a.TotalFare
          ) || [];

    const sortedResultList = filteredResults.ResultList;
    sortedResultList[resultIndex] = {
      TotalCount: filteredResults.ResultList[resultIndex].TotalCount,
      ResultList: sortedRecords,
    };

    setFilteredAvailableFlights({
      ...filteredResults,
      ResultList: sortedResultList,
    });
  };

  const handleFilterByPriceRange = (range: [number, number]) => {
    const [min, max] = range;
    const isReturning = selectedFlights.length > 0;
    const resultIndex = isReturning ? 1 : 0;
    if (!availableFlights) {
      return;
    }

    const flights = { ...availableFlights };
    const filteredResults = flights.ResultList[resultIndex].ResultList.filter(
      (a: AirlineResultItem) => a.TotalFare >= min && a.TotalFare <= max
    );

    const sortedResultList = [...flights.ResultList];
    sortedResultList[resultIndex] = { TotalCount: filteredResults.length, ResultList: filteredResults };

    const newFiltered = {
      ...flights,
      TotalCount: filteredResults.length,
      ResultList: sortedResultList,
    };

    setFilteredAvailableFlights(newFiltered);
    handleSort(sortBy, newFiltered);
  };

  const handleSortByDuration = (order: string | null, data) => {
    const isReturning = selectedFlights.length > 0;
    const resultIndex = isReturning ? 1 : 0;
    const filteredResults = data || filteredAvailableFlights;
    if (!filteredResults) {
      return;
    }

    const sortedRecords =
      order === "asc"
        ? filteredResults.ResultList[resultIndex].ResultList.sort(
            (a: AirlineResultItem, b: AirlineResultItem) =>
              getDurationInMinutes(a.TotalFlightDuration) - getDurationInMinutes(b.TotalFlightDuration)
          )
        : filteredResults.ResultList[resultIndex].ResultList.sort(
            (a: AirlineResultItem, b: AirlineResultItem) =>
              getDurationInMinutes(b.TotalFlightDuration) - getDurationInMinutes(a.TotalFlightDuration)
          ) || [];
    //
    const sortedResultList = filteredResults.ResultList;
    sortedResultList[resultIndex] = {
      TotalCount: filteredResults.ResultList[resultIndex].TotalCount,
      ResultList: sortedRecords,
    };

    setFilteredAvailableFlights({
      ...filteredResults,
      ResultList: sortedResultList,
    });
  };

  useEffect(() => {
    if (!availableFlights) return;

    let uniqueAirlines = [];
    if (availableFlights) {
      const legIndex = isReturning && isDomestic ? 1 : 0;
      const legResults = availableFlights.ResultList[legIndex] as FlightResultItem;
      uniqueAirlines = legResults.ResultList.map((d) => {
        return {
          code: d.FlightDetails[0].OperatingAirlineCode,
          name: d.FlightDetails[0].OperatingAirlineName,
        };
      });
    }

    const filteredUniqueAirlines = uniqueAirlines.filter(
      (a, i) => uniqueAirlines.findIndex((s) => a.code === s.code) === i
    );
    setAirlines([...filteredUniqueAirlines]);
    setSelectedAirlines(filteredUniqueAirlines.map((a) => a.code));
  }, [availableFlights, isReturning, isDomestic]);

  useEffect(() => {
    const origin = allAirports.find((airport) => airport.Code === formData.OriginLocationCode);
    const destination = allAirports.find((airport) => airport.Code === formData.DestinationLocationCode);

    if (!origin || !destination) return;
    const isDomestic = origin?.CountryCode === destination?.CountryCode;

    updateFormData({ isDomestic });
  }, [airports, formData.OriginLocationCode, formData.DestinationLocationCode]);

  const handleSortByDate = (order: string | null, data) => {
    const isReturning = selectedFlights.length > 0;
    const resultIndex = isReturning ? 1 : 0;
    const filteredResults = data || filteredAvailableFlights;
    if (!filteredResults) {
      return;
    }

    const sortedRecords =
      order === "asc"
        ? filteredResults.ResultList[resultIndex].ResultList.sort((a: AirlineResultItem, b: AirlineResultItem) =>
            moment(b.FlightDetails[0].DepartureDate).diff(moment(a.FlightDetails[0].DepartureDate))
          )
        : filteredResults.ResultList[resultIndex].ResultList.sort((a: AirlineResultItem, b: AirlineResultItem) =>
            moment(a.FlightDetails[0].DepartureDate).diff(moment(b.FlightDetails[0].DepartureDate))
          ) || [];

    const sortedResultList = filteredResults.ResultList;
    sortedResultList[resultIndex] = {
      TotalCount: filteredResults.ResultList[resultIndex].TotalCount,
      ResultList: sortedRecords,
    };

    setFilteredAvailableFlights({
      ...filteredResults,
      ResultList: sortedResultList,
    });
  };

  const getDrawerContainer = () => drawerContainerRef.current as unknown as HTMLElement;

  const handleDateChange = (date: Moment) => {
    updateFormData({
        DepartureDate: date
    });
}

  useEffect(() => {   
    searchFlights();
  }, [searchFlights]);


  const hasResults = availableFlights?.TotalCount > 0;
  const resultCount = useMemo(() => {
    if (isDomestic && !isOneWay) {
      return isReturning
        ? filteredAvailableFlights?.ResultList[1]?.TotalCount || 0
        : filteredAvailableFlights?.ResultList[0]?.TotalCount || 0;
    }

    return filteredAvailableFlights?.ResultList[0]?.TotalCount || 0;
  }, [filteredAvailableFlights, isReturning]);

  const handleEmptyResult = () => {
    if (hasResults) {
      handleResetFilters();
    } else {
      setOpenModify(true);
    }
  };

  if (loadingData) {
    return <FlightRequestLoading />;
  }

  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <FlightHeaderNavigation
            refetchSearch={searchFlights}
            navigateHandler={handleNavigation}
            modify={openModify}
            onModifyChange={(value) => setOpenModify(value)}
          />
        </Col>
      </Row>

      {/*  <= XS Devices */}
      <Row>
        <Col xs={24} lg={0}>
          {isOneWay && <FlightDateFilter handleDateChange={handleDateChange} refetchSearch={searchFlights} />}
          <MobileContentWrapper>
            <Row>
              <Col xs={24} lg={0}>
                <FlightSelectInfo count={resultCount} isDomestic={isDomestic} isReturning={isReturning} />
                <ListingFilterMobile
                  isDomestic={isDomestic}
                  handleStopoverFiltering={handleStopoverFiltering}
                  handleAirlineFiltering={handleAirlineFiltering}
                  handleSort={handleSort}
                  sortBy={sortBy}
                  handleFilterByPriceRange={handleFilterByPriceRange}
                  farePriceRange={farePriceRange}
                  selectedAirlines={selectedAirlines}
                  airlines={airlines}
                  selectedStopOver={selectedStopOver}
                />

                {!error && filteredAvailableFlights?.TotalCount === 0 && (
                  <EmptyFlightResult onAction={handleEmptyResult} emptyOnFilter={hasResults} />
                )}

                {error ? <Empty description={error} /> : renderAvailableFlights()}
              </Col>
            </Row>
          </MobileContentWrapper>
        </Col>
      </Row>

      {/*  Desktop devices*/}
      <Row>
        <Col xs={0} lg={24}>
          <DesktopContentWrapper className="container" ref={drawerContainerRef}>
            <Row gutter={6}>
              <Col span={5}>
                <ListingFilterDesktop
                  isDomestic={isDomestic}
                  handleStopoverFiltering={handleStopoverFiltering}
                  handleAirlineFiltering={handleAirlineFiltering}
                  handleFilterByPriceRange={handleFilterByPriceRange}
                  onResetFilters={handleResetFilters}
                  farePriceRange={farePriceRange}
                  selectedAirlines={selectedAirlines}
                  selectedStopOver={selectedStopOver}
                  airlines={airlines}
                />
              </Col>
              <Col span={18}>
                {isOneWay && <FlightDateFilter handleDateChange={handleDateChange} refetchSearch={searchFlights} />}
                <ListingHeaderContentWrapper>
                  <FlightSelectInfo isDomestic={isDomestic} count={resultCount} isReturning={isReturning} />
                  <Sort onChange={handleSort} sortBy={sortBy} />
                </ListingHeaderContentWrapper>

                {!error && filteredAvailableFlights?.TotalCount === 0 && (
                  <EmptyFlightResult onAction={handleEmptyResult} emptyOnFilter={hasResults} />
                )}
                {error ? <Empty description={error} /> : renderAvailableFlights()}
              </Col>
            </Row>

            <Drawer
              title="Flight Details"
              placement="right"
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
              footer={<FlightPriceBreakdown />}
              getContainer={getDrawerContainer}
            >
              <FlightItinerary />
            </Drawer>
          </DesktopContentWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default SearchListings;
