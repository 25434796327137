import React from "react";
import { Button, Form, Row, Col } from "antd";
import {
  AirportFieldsWrapper,
  DepartureWrapper,
  FormCard,
  FormWrapper,
  SwitcherWrapper,
  TripRadioGroupWrapper,
} from "./styles";
import PassengerCountSelector from "../PassegerSelector";
import translations from "../../screens/Search/translations";
import { useI18nContext } from "../../utils/I18n";
import { getDisabledDateRange } from "../../utils/helpers";
import { MulticityFlightSearchForm } from "./MultiCity";
import FlightSearchDesktopNav from "./FlighSearchDesktopNav";
import { tripModesMultiCity } from "../../screens/Search/constant";
import { useFlightSearchStore } from "../../store/flightSearch/flightSearchStore";
import StyledRadioGroup from "../CustomAntdComponents/StyledRadioGroup";
import AirportSelector from "../RouteSelector";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction-hr.svg";
import type { Moment } from "moment";
import { validateRoundTripDates } from "./helper";
import DateRangePicker from "../CustomAntdComponents/StyledDateRangePicker/DateRangePicker";
import StyledDatePicker from "../CustomAntdComponents/StyledDatePicker/DatePicker";

function FlightSearchWebView(props) {
  const {
    state: { t },
  } = useI18nContext();
  const { formData, updateFormData } = useFlightSearchStore();

  const flightModes = tripModesMultiCity(t(translations));

  const {
    isMultiCity,
    isRoundTrip,
    airportOptions,
    validateDestination,
    validateDeparture,
    MultiCityMaxRoute,
    handleSwitchAirports,
    form,
  } = props;

  const { DepartureDate, ReturnDate, tripType, DestinationLocationCode, OriginLocationCode } = formData;

  const onFormInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const onDateRangeChange = (values: [Moment, Moment]) => {
    if (values) {
      onFormInputChange(values[0], "DepartureDate");
      onFormInputChange(values[1], "ReturnDate");
    } else {
      onFormInputChange("", "DepartureDate");
      onFormInputChange("", "ReturnDate");
    }
  };

  return (
    <>
      <FlightSearchDesktopNav />
      <FormWrapper>
        <FormCard>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <TripRadioGroupWrapper>
                <StyledRadioGroup
                  options={flightModes}
                  value={tripType}
                  onChange={(e) => onFormInputChange(e.target.value, "tripType")}
                />
              </TripRadioGroupWrapper>
            </Col>

            {isMultiCity ? (
              <Col span={18}>
                <MulticityFlightSearchForm
                  airportOptions={airportOptions}
                  MultiCityMaxRoute={MultiCityMaxRoute}
                  form={form}
                />
              </Col>
            ) : (
              <>
                <Col span={12}>
                  <AirportFieldsWrapper>
                    <Row gutter={[16, 0]}>
                      <Col span={12}>
                        <DepartureWrapper>
                          <Form.Item
                            name="OriginLocationCode"
                            rules={[
                              {
                                validator: validateDeparture,
                              },
                            ]}
                          >
                            <AirportSelector
                              label={t(translations).inputs.fromLabel}
                              placeholder={t(translations).inputs.fromPlaceholder}
                              value={OriginLocationCode}
                              options={airportOptions}
                              icon="takeoff"
                              tripType="flight"
                              onChange={(value) => onFormInputChange(value, "OriginLocationCode")}
                            />
                          </Form.Item>
                          <SwitcherWrapper onClick={handleSwitchAirports}>
                            <DirectionIcon />
                          </SwitcherWrapper>
                        </DepartureWrapper>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="DestinationLocationCode"
                          rules={[
                            {
                              validator: validateDestination,
                            },
                          ]}
                        >
                          <AirportSelector
                            label={t(translations).inputs.toLabel}
                            placeholder={t(translations).inputs.toPlaceholder}
                            value={DestinationLocationCode}
                            options={airportOptions}
                            icon="land"
                            tripType="flight"
                            onChange={(value) => onFormInputChange(value, "DestinationLocationCode")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </AirportFieldsWrapper>
                </Col>
                <Col span={6}>
                  {isRoundTrip ? (
                    <Form.Item name="FlightDateRange" rules={[{ validator: validateRoundTripDates }]}>
                      <DateRangePicker
                        value={[DepartureDate, ReturnDate]}
                        onChange={onDateRangeChange}
                        disabledDate={getDisabledDateRange}
                        placeholder={[t(translations).inputs.departureDate, t(translations).inputs.arrivalDate]}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="DepartureDate"
                      rules={[{ required: true, message: t(translations).validations.departureDate }]}
                    >
                      <StyledDatePicker
                        label={t(translations).inputs.departureDate}
                        value={DepartureDate}
                        disabledDate={getDisabledDateRange}
                        onChange={(value) => onFormInputChange(value, "DepartureDate")}
                      />
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
            <Col span={6}>
              <PassengerCountSelector />
            </Col>

            <Col span={4} offset={20}>
              <Button type="primary" htmlType="submit" size="large" block>
                {t(translations).searchButton}
              </Button>
            </Col>
          </Row>
        </FormCard>
      </FormWrapper>
    </>
  );
}

export default FlightSearchWebView;
