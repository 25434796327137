import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { StyledParagraph } from "../CustomAntdComponents/Typography";
import moment from "moment";
import type { Moment } from "moment";
import styled, { css } from "styled-components";
import StyledInput from "../CustomAntdComponents/StyledInput";
 
type Props = {
  label: string;
  onChange: (date: Moment) => void;
  value?: Moment;
  startYearIndex?: number;
};
// 
const CustomDateInput = ({ onChange,  value, label, startYearIndex }: Props) => {
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [hasError, setHasError] = useState(false);

    // Restrict unwanted characters in number inputs
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    };

    const handleChange = (setter, val, rules?: { maxLength: number, maxValue?: number }) => {
      
      
        // const numericValue = val
        const numericValue = val.replace(/\D/g, '');
         

         // Apply max length validation
      if (numericValue.length > rules.maxLength) {
        setHasError(true);
        // return;
      }

      // Apply max value validation if provided
      if (rules.maxValue && parseInt(numericValue) > rules.maxValue) {
        setHasError(true);
        // return;
      }else{

        setHasError(false);
      }

        
        setter(numericValue);
      };
  
  
     
  
    // Effect to handle input changes and validate date
    useEffect(() => {
      if (year && month && day) {
        const dateStr = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        const date = moment(dateStr, "YYYY-MM-DD", true);
        
        onChange(date);
        
        if (date.isValid()) {
          
          
          setHasError(false);
        } else {
          
          setHasError(true);
        }
      }
    }, [year, month, day]);
  
    // Effect to initialize state from value prop
    useEffect(() => {
      if (value) {
        
      }
    }, [value]);

    
    return (
      <Wrapper error={hasError}>
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <StyledParagraph>{label}</StyledParagraph>
          </Col>
          <Col span={8}>
            <StyledInput
              placeholder="DD"
              value={day}
              onChange={(val) =>  handleChange(setDay, val, { maxLength: 2, maxValue: 31 })}
              type="text"
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col span={8}>
            <StyledInput
              placeholder="MM"
              value={month}
              onChange={(val) =>  handleChange(setMonth, val, { maxLength: 2, maxValue: 12 })}
              type="text"
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col span={8}>
            <StyledInput
              placeholder="YYYY"
              value={year}
              onChange={(val) => handleChange(setYear, val, { maxLength: 4 })}
              type="text"
              onKeyDown={handleKeyDown}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  };

export const Wrapper = styled.div<{ error?: boolean }>`
  ${({ error }) =>
    error &&
    css`
      .ant-input {
        .ant-select-selector {
          border-color: #ff3b30 !important;
        }
      }
      .ant-input-affix-wrapper {
        border-color: #ff3b30 !important;
      }
      .ant-input{
        border-color: #ff3b30 !important;
        }
      input {
        border-color: #ff3b30 !important;
        box-shadow: 0 0 0 1px #ff3b30 !important;
      }
    `}
`;
export default CustomDateInput;
