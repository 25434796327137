import styled, { css } from 'styled-components';
import {GREY_10, BLACK_200, GREY_6, GREY_5, GREY} from '../../../utils/colors';
import device from '../../../utils/mediaQuery';

export const Wrapper = styled.div<{ filled?: boolean, hasPrefix: boolean }>`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: 14px !important;
    pointer-events: none;
    left: 12px;
    top: 17px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-placeholder {
    color: gray;
  }

  .as-label {
    top: -8px;
    font-size: 14px !important;
    background: #fff;
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
  }


  .ant-input {
    width: 100%;
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
  }

  .ant-input:focus, .ant-input-focused {
    box-shadow: none !important;
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
  }

  @media ${device.large} {
    .ant-input {
      border: solid 1px ${GREY_6} !important;
    }

    .ant-input:focus, .ant-input-focused {
      border: solid 1px ${GREY_6} !important;
    }
  }

    ${({ filled }) => filled && css`
    .ant-input {
      background: ${GREY_10} !important;
    }

    .as-label {
      background: #fff;
    }
  `}

    ${({ hasPrefix }) => hasPrefix && css`
        .as-placeholder {
          left: 35px;
        }
        
        .ant-input {
            padding-left: 35px !important;
        }
  `}

    svg {
      path {
        fill: ${BLACK_200} !important;
      }
    }
`;

export const CustomWrapper = styled.div`
   border: solid 1px ${GREY_6};
   border-radius: 12px !important;
    
    .ant-input-affix-wrapper {
        border-radius: 12px !important;
        padding: 13.2px 11px;
        font-size: 14px;
        line-height: 1.4;
        border: none;

    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input,
    .ant-input:focus,
    .ant-input-focussed
    {
        border: none;
        box-shadow: none;
    }
    
    .ant-input {
        border-radius: 12px !important;
        padding-left: 2px !important;
        font-size: 12px;
    }

    svg {
        width: 20px;
        height: 20px;
        
      path {
        fill: ${BLACK_200} !important;
      }
    }
     
    @media ${device.extraLarge} {
        .ant-input {
             font-size: 14px;
        }
    }
`;

export const PrefixWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 5px;
`;
export const Label = styled.span`
    color: ${GREY};
`;

export const PrefixIconWrapper = styled.div`
    position: absolute;
    top: 13px;
    left: 10px;
  
    svg {
      width: 20px;
      height: 20px;
    }
`;
