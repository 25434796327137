import React, {useCallback, useEffect} from 'react';
import {Form} from "antd"
import {PassengerDataWrapper} from "../styles";
import {useFlightSearchStore} from "../../../store/flightSearch/flightSearchStore";
import PassengerFormItem from "../../../components/PassengerFormItem";
import {generateCategoryInputs, getPassengerData} from "../helper";
import {ADULT, CHILDREN, INFANT} from "../../../components/PassengerFormItem/helpers";
import {useStore} from "../../../store/global/appStore";
import {getAirlineApi} from "../../../utils/api";
import {GET_AIRLINES} from "../../../utils/constants";

type Props = {
    form: any,
    errorFields: any,
    onSetErrorFields: (arg: any) => void
}
const FlightPassengerData = ({ form, errorFields, onSetErrorFields }: Props) => {
    const {
        formData: {
            numberOfAdults,
            numberOfChildren,
            numberOfInfants,
        }
    } = useFlightSearchStore()

    const { airlines, setData } = useStore();

    const getAirlines = useCallback(async () => {
        const airlinesApi = await getAirlineApi(GET_AIRLINES);
        if(airlinesApi.status === 200) {
            setData(airlinesApi.data.Results, 'airlines');
        }
    }, []);

    useEffect(() => {
        if(!airlines.length) {
            getAirlines();
        }
    }, [getAirlines]);

    const childrenCount = numberOfChildren || 0;
    const infantsCount = numberOfInfants || 0;
    const savedPaxData = getPassengerData();
    const adultInputs = generateCategoryInputs(numberOfAdults, savedPaxData);
    const childrenInputs = generateCategoryInputs(childrenCount);
    const infantInputs = generateCategoryInputs(infantsCount);

    const adultsValue = Form.useWatch('adults', form) || [];
    const childrenValue = Form.useWatch('children', form) || [];
    const infantsValue = Form.useWatch('infants', form) || [];

    return (
            <PassengerDataWrapper>
                {/* Adults */}
                <Form.List name="adults" initialValue={adultInputs}>
                    {(fields) => (
                        <>
                            {fields.map((field, key) => (
                                <PassengerFormItem key={`${field.name}-${key}`} field={field} data={adultsValue[key]} passengerType={ADULT} form={form} fieldName="adults" errorFields={errorFields} onSetErrorFields={onSetErrorFields} />
                            ))}
                        </>
                    )}
                </Form.List>

                {/* Children */}
                <Form.List name="children" initialValue={childrenInputs}>
                    {(fields) => (
                        <>
                            {fields.map((field, key) => (
                                <PassengerFormItem  key={`${field.name}-${key}`} field={field} data={childrenValue[key]} passengerType={CHILDREN} form={form} fieldName="children" errorFields={errorFields} onSetErrorFields={onSetErrorFields} />
                            ))}
                        </>
                    )}
                </Form.List>

                {/* Infants */}
                <Form.List name="infants" initialValue={infantInputs}>
                    {(fields) => (
                        <>
                            {fields.map((field, key) => (
                                <PassengerFormItem key={`${field.name}-${key}`} field={field} data={infantsValue[key]} passengerType={INFANT} form={form} fieldName="infants" errorFields={errorFields} onSetErrorFields={onSetErrorFields} />
                            ))}
                        </>
                    )}
                </Form.List>
            </PassengerDataWrapper>
    );
};

export default FlightPassengerData;
