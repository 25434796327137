export default {
  en: {
    title: 'Passenger Details',
    passenger: 'Passenger',
    passengers: 'Passengers',
    cancel: 'Cancel',
    submit: 'Submit',
    proceed: 'Proceed',
    priceUpdate: 'There has been a price update from',
    to: 'to',
    provideName: 'Please enter names as they appear on your passport',
    itineraryTitle: 'Itinerary Breakdown',
    next: 'Next',
    priceUpdateTitle: 'Price Update',
    seatSelection: 'Seat Selection',
    seatSelectionTitle: 'Select Seats',
    seatSelectionText: 'Select your seats for',
    available: 'Available',
    booked: 'Booked',
    blocked: 'Blocked',
    selected: 'Selected',
    done: 'Done',
    input: {
      title: 'Title',
      country: 'Country',
      city: 'City',
      firstName: 'First Name',
      lastName: 'Surname',
      email: 'Email Address',
      phone: 'Phone Number',
      dob: 'Date of Birth',
      passportNumber: 'Passport Number',
      issuanceCountry: 'Issuance Country',
      passportExpiration: 'Passport Expiration Date',
      passportIssuanceDate: 'Passport Issuance Date',
      frequentFlyerAirline: 'Frequent flyer airline',
      frequentFlyerNumber: 'Frequent flyer number',
      address: 'Address',
      nok: 'Next Of Kin Name',
      nokEmail: 'Next Of Kin Email ',
      nokPhone: 'Next Of Kin Phone Number',
    },
    validations: {
      title: 'Please select title',
      country: 'Please select country',
      city: 'Please select city',
      firstName: 'Please enter first name',
      lastName: 'Please enter last name',
      email: 'Please enter email address',
      emailInvalid: 'Please enter a valid email address',
      phone: 'Please enter phone number',
      contactPhone: 'Please enter contact phone number',
      contactEmail: 'Please enter contact email address',
      dob: 'Please select DOB',
      passportNumber: 'Please enter passport number',
      issuanceCountry: 'Please select country',
      passportExpiration: 'Please select expiration date',
      passportIssuanceDate: 'Please select issuance date',
      address: 'Please enter address',
      nok: 'Please enter next of kin name',
    },
  },
  fr: {
    title: 'Détails sur le passager',
    passenger: 'Passager',
    passengers: 'Passagers',
    cancel: 'Annuler',
    submit: 'Soumettre',
    proceed: 'Procédez à ',
    priceUpdate: 'Il y a eu une mise à jour des prix de ',
    to: 'à',
    provideName: 'Veuillez indiquer les noms tels qu\'ils figurent sur votre passeport ',
    itineraryTitle: 'Répartition des itinéraires ',
    next: 'Suivant',
    priceUpdateTitle: 'Mise à jour des prix',
    seatSelection: 'Sélection de sièges',
    seatSelectionTitle: 'Sélection de sièges',
    seatSelectionText: 'Sélectionnez vos sièges pour',
    available: 'Disponible',
    booked: 'Réservé',
    blocked: 'Bloqué',
    selected: 'Sélectionné',
    done: 'Faites',
    input: {
      title: 'Titre',
      country: 'Pays',
      city: 'Ville',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'Adresse e-mail',
      phone: 'Numéro de téléphone ',
      dob: 'Date de naissance ',
      passportNumber: 'Numéro de passeport ',
      issuanceCountry: 'Pays de délivrance',
      passportExpiration: 'Expiration du passeport ',
      passportIssuanceDate: 'Passport Issuance',
      frequentFlyerAirline: 'Frequent flyer airline',
      frequentFlyerNumber: 'Frequent flyer number',
      address: 'Adresse',
      nok: 'Proche parent',
      nokEmail: 'Adresse électronique du N.O.K.',
      nokPhone: 'Numéro de téléphone du N.O.K.',
    },
    validations: {
      title: 'Veuillez sélectionner le titre',
      country: 'Veuillez sélectionner le pays',
      city: 'Veuillez sélectionner la ville',
      firstName: 'Please enter first name',
      lastName: 'Veuillez saisir le nom de famille',
      email: "Veuillez saisir l'adresse e-mail",
      emailInvalid: 'Veuillez saisir une adresse électronique valide',
      phone: 'Veuillez saisir le numéro de téléphone',
      contactPhone: 'Please enter contact phone number',
      contactEmail: 'Please enter contact email address',
      dob: 'Veuillez sélectionner DOB',
      passportNumber: 'Veuillez saisir le numéro de passeport',
      issuanceCountry: 'Veuillez sélectionner le pays',
      passportExpiration: "Veuillez sélectionner la date d'expiration",
      passportIssuanceDate: 'Please select issuance date',
      address: 'Veuillez saisir l\'adresse',
      nok: 'Veuillez entrer le nom du parent le plus proche',
    },
  },
  sp: {
    title: 'Detalles del pasajero',
    passenger: 'Pasajero',
    passengers: 'Pasajeros',
    cancel: 'Cancelar',
    submit: 'Enviar',
    proceed: 'Proceder',
    priceUpdate: 'Se ha actualizado el precio de ',
    to: 'a',
    provideName: 'Por favor, introduzca los nombres tal y como aparecen en su pasaporte ',
    itineraryTitle: 'Desglose del itinerario ',
    next: 'Siguiente',
    priceUpdateTitle: 'Actualización de precios',
    seatSelection: 'Selección de asientos',
    seatSelectionTitle: 'Selección de asientos',
    seatSelectionText: 'Seleccione sus asientos para',
    available: 'Disponible',
    booked: 'Reservado',
    blocked: 'Bloqueado',
    selected: 'Seleccionado',
    done: 'Hecho',
    input: {
      title: 'Título',
      country: 'País',
      city: 'Ciudad',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Dirección de correo electrónico',
      phone: 'Número de teléfono ',
      dob: 'Fecha de nacimiento ',
      passportNumber: 'Número de pasaporte ',
      issuanceCountry: 'País de emisión',
      passportExpiration: 'Caducidad del pasaporte ',
      passportIssuanceDate: 'Passport Issuance',
      frequentFlyerAirline: 'Frequent flyer airline',
      frequentFlyerNumber: 'Frequent flyer number',
      address: 'Dirección',
      nok: 'Parientes más cercanos',
      nokEmail: 'Dirección de correo electrónico del N.O.K.',
      nokPhone: 'Número de teléfono del N.O.K.',
    },
    validations: {
      title: 'Por favor, seleccione el título',
      country: 'Por favor, seleccione el país',
      city: 'Por favor, seleccione la ciudad',
      firstName: 'Por favor, introduzca el nombre',
      lastName: 'Por favor, introduzca el apellido',
      email: 'Por favor, introduzca la dirección de correo electrónico',
      emailInvalid: 'Por favor, introduzca una dirección de correo electrónico válida',
      phone: 'Por favor, introduzca el número de teléfono',
      contactPhone: 'Please enter contact phone number',
      contactEmail: 'Please enter contact email address',
      dob: 'Por favor, seleccione la fecha de nacimiento',
      passportNumber: 'Por favor, introduzca el número de pasaporte',
      issuanceCountry: 'Por favor, seleccione el país',
      passportIssuanceDate: 'Please select issuance date',
      passportExpiration: 'Seleccione la fecha de caducidad',
      address: 'Por favor, introduzca la dirección',
      nok: 'Por favor, introduzca el nombre del familiar más cercano',
    },
  },
  sw: {
    title: 'Maelezo ya Abiria',
    passenger: 'Abiria',
    passengers: 'Abiria',
    cancel: 'Ghairi',
    submit: 'Wasilisha',
    proceed: 'Endelea',
    priceUpdate: 'Kumekuwa na sasisho la bei kutoka',
    to: 'kwa',
    provideName: 'Tafadhali weka majina jinsi yanavyoonekana kwenye pasipoti yako',
    itineraryTitle: 'Uchanganuzi wa Ratiba',
    next: 'Inayofuata',
    priceUpdateTitle: 'Sasisho la Bei',
    seatSelection: 'Uteuzi wa Kiti',
    seatSelectionTitle: 'Uteuzi wa Kiti',
    seatSelectionText: 'Chagua viti vyako',
    available: 'Inapatikana',
    booked: 'Imehifadhiwa',
    blocked: 'Imezuiwa',
    selected: 'Imechaguliwa',
    done: 'Imekamilika',
    input: {
      title: 'Kichwa',
      country: 'Nchi',
      city: 'Jiji',
      firstName: 'Jina la kwanza',
      lastName: 'Jina la familia',
      email: 'Barua pepe',
      phone: 'Nambari ya simu',
      dob: 'Tarehe ya kuzaliwa',
      passportNumber: 'Nambari ya Pasipoti',
      issuanceCountry: 'Nchi ya Utoaji',
      passportExpiration: 'Kuisha Muda wa Pasipoti',
      passportIssuanceDate: 'Passport Issuance',
      frequentFlyerAirline: 'Frequent flyer airline',
      frequentFlyerNumber: 'Frequent flyer number',
      address: 'Anwani',
      nok: 'Karibu na Kin',
      nokEmail: 'N.O.K Anwani ya barua pepe',
      nokPhone: 'Nambari ya simu ya N.O.K',
    },
    validations: {
      title: 'Tafadhali chagua kichwa',
      country: 'Tafadhali chagua nchi',
      city: 'Tafadhali chagua jiji',
      firstName: 'Tafadhali ingiza jina la kwanza',
      lastName: 'Tafadhali ingiza jina la mwisho',
      email: 'Tafadhali ingiza barua pepe',
      emailInvalid: 'Tafadhali weka barua pepe halali',
      phone: 'Tafadhali weka nambari ya simu',
      contactPhone: 'Please enter contact phone number',
      contactEmail: 'Please enter contact email address',
      dob: 'Tafadhali chagua DOB',
      passportNumber: 'Tafadhali ingiza nambari ya pasipoti',
      issuanceCountry: 'Tafadhali chagua nchi',
      passportIssuanceDate: 'Please select issuance date',
      passportExpiration: 'Tafadhali chagua tarehe ya mwisho',
      address: 'Tafadhali weka anwani',
      nok: 'Tafadhali weka jina la jamaa wa karibu',
    },
  },
};
