import moment, { Moment } from 'moment';

export const titleOptions : SelectOption[] = [{ label: 'Mr', value: 'Mr' }, { label: 'Mrs', value: 'Mrs' }, { label: 'Miss', value: 'Miss' }];

export const genderOptions : SelectOption[] = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];

export const allowedAgedRange = (current: Moment, passengerType: string): boolean => {
  switch (passengerType) {
    case 'ADT':
      return current && current > moment().subtract('12', 'years').endOf('day');
    case 'CHD':
      return current && !current.isBetween(moment().subtract('11', 'years'), moment().subtract('2', 'years').endOf('day'));
    case 'INF':
      return current && !current.isBetween(moment().subtract('1', 'years'), moment().endOf('day'));
    default:
      return current && current > moment().subtract('12', 'years').endOf('day');
  }
};


export const validateDOB = (value, passengerType) => {
  if(!value){
    return Promise.reject(new Error('Please input valid date of birth'));
  }
  const currentDate = moment().startOf('day');
  if(!value.isValid()){
    return Promise.reject(new Error('Please input valid date of birth'));
    
  }
  if (value.isAfter(currentDate)) {
    return Promise.reject(new Error('Please input valid date of birth'));
  }

   // Check if date is older than 150 years from today
   if (value.isBefore(moment().subtract(150, 'years'))) {
    return Promise.reject(new Error('Passenger should not be older than 150 years'));
  }

  if(passengerType === "ADT"){
    if(value.isAfter(moment().subtract('12', 'years'))){
      return Promise.reject(new Error('Adult passenger should be 12 years or older'));
    }
  } else if(passengerType === "CHD"){
    if(!value.isBetween(moment().subtract('11', 'years'), moment().subtract('2', 'years').endOf('day'))){
      return Promise.reject(new Error('Child passenger should be 2 - 11 years old'));
    }
  } else if(passengerType === "INF"){
    if(!value.isBetween(moment().subtract('1', 'years'), moment().endOf('day'))){
      return Promise.reject(new Error('Infant passenger should be 0 - 1 years old'));
    }
  }

  return Promise.resolve();
};

export const validateNoFutureDate = (value) => {

  if(!value){
    return Promise.reject(new Error('Please input date'));
  }
  if(!value.isValid()){
    return Promise.reject(new Error('Please input valid date'));
    
  }

  const currentDate = moment().endOf('day');
  if (value.isAfter(currentDate)) {
    return Promise.reject(new Error('Issuance date cannot be a future date'));
  }


  return Promise.resolve();
};

export const validatePassportExpiration = (value) => {
  if(!value){
    return Promise.reject(new Error('Please input date'));
  }
  if(!value.isValid()){
    return Promise.reject(new Error('Please input valid date'));
    
  }

  const currentDate = moment().startOf('day');
  if (value.isBefore(currentDate)) {
    return Promise.reject(new Error('Passport expiration date should be in the future'));
  }


  return Promise.resolve();
};
